import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_COURSE_DETAILS_REQUEST = "GET_COURSE_DETAILS_REQUEST";
export const GET_COURSE_DETAILS_SUCCESS = "GET_COURSE_DETAILS_SUCCESS";
export const GET_COURSE_DETAILS_FAILURE = "GET_COURSE_DETAILS_FAILURE";

export const GET_COURSE_LIST_REQUEST = "GET_COURSE_LIST_REQUEST";
export const GET_COURSE_LIST_SUCCESS = "GET_COURSE_LIST_SUCCESS";
export const GET_COURSE_LIST_FAILURE = "GET_COURSE_LIST_FAILURE";

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILURE = "CREATE_COURSE_FAILURE";

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILURE = "UPDATE_COURSE_FAILURE";

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";

export const getCourses = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSE_LIST_REQUEST });
      const url = `${BASE_URL}/course/admin/list/v1?searchQuery=${
        searchQuery && searchQuery !== undefined ? searchQuery : ""
      }`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_COURSE_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_COURSE_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getCourseDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSE_DETAILS_REQUEST });
      const url = `${BASE_URL}/course/admin/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_COURSE_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_COURSE_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const createCourse = (data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_COURSE_REQUEST });
      if (file) {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/course/admin/upload/course/image/v1`,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          const url = imgRes.data.data.sUrl;
          await axios.put(url, file, {
            headers: { "Content-Type": file.type },
          });
          data.image = imgRes.data.data.sPath;
          // return imgRes.data.data.sPath;
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      }
      const url = `${BASE_URL}/course/create/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_COURSE_SUCCESS, payload: response.data });
      toast.success("Course Created Successfully");
      navigate("/course/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_COURSE_FAILURE, payload: error.message });
    }
  };
};

export const updateCourse = (id, data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_COURSE_REQUEST });
      if (file instanceof File) {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/course/admin/upload/course/image/v1`,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          const url = imgRes.data.data.sUrl;
          await axios.put(url, file, {
            headers: { "Content-Type": file.type },
          });
          data.image = imgRes.data.data.sPath;
          // return imgRes.data.data.sPath;
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      }
      const url = `${BASE_URL}/course/update/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_COURSE_SUCCESS, payload: response.data });
      toast.success("Course Updated Successfully");
      navigate("/courses/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_COURSE_FAILURE, payload: error.message });
    }
  };
};

export const deleteCourse = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_COURSE_UPDATED" });
      dispatch({ type: DELETE_COURSE_REQUEST });
      const url = `${BASE_URL}/course/delete/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_COURSE_SUCCESS, payload: response.data });
      toast.success("Course Deleted Successfully");
      try {
        dispatch({ type: GET_COURSE_LIST_REQUEST });
        const url = `${BASE_URL}/course/admin/list/v1`;
        const response = await axios.get(url, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        dispatch({ type: GET_COURSE_LIST_SUCCESS, payload: response.data });
      } catch (error) {
        console.error(error);
        dispatch({ type: GET_COURSE_LIST_FAILURE, payload: error.message });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_COURSE_FAILURE, payload: error.message });
    }
  };
};
